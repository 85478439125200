import useEmployeeActions from "./useEmployeeActions"
import useEmployeeActivationActions from "./useEmployeeActivationActions"
import useEmployeeDeactivationActions from "./useEmployeeDeactivationActions"
import useFileActions from "./useFileActions"
import usePayrollActions from "./usePayrollActions"
import {userHasAuthorization} from "../util/role";
import {useAppSelector} from "../redux/hooks";
import useCustomerActions from "./useCustomerActions"
import { useEffect } from "react"
import useFortnoxIntegrationActions from "./integrations/useFortnoxIntegrationActions"
import usePlandayIntegrationActions from "./integrations/usePlandayIntegrationActions"

const useInitialDataFetch = () => {
    const { getPendingEmployees: getEmployeesPendingActivation } = useEmployeeActivationActions()
    const { getPendingEmployees: getEmployeesPendingDeactivation } = useEmployeeDeactivationActions()
    const { getCustomer } = useCustomerActions()
    const { getEmployees } = useEmployeeActions()
    const { getPayrollData } = usePayrollActions()
    const { getFiles } = useFileActions()
    const user = useAppSelector(state => state.auth.authenticatedUser)
    const currentCustomerId = useAppSelector(state => state.auth.currentCustomerId)
    const customer = useAppSelector(state => state.customer.customer)
    const { getStatus: getFortnoxStatus } = useFortnoxIntegrationActions()
    const { getStatus: getPlandayStatus } = usePlandayIntegrationActions ()

    const fetchData = () => {
        getCustomer()
        getEmployees()
        getFiles()

        if (!user || !currentCustomerId) {
            return
        }

        if (userHasAuthorization(user, currentCustomerId, role => role.allowManageEmployeeActivations)) {
            getEmployeesPendingActivation()
            getEmployeesPendingDeactivation()
        }

        if (userHasAuthorization(user, currentCustomerId, role => role.allowManagePayroll)) {
            getPayrollData()
        }
    }

    useEffect(() => {
        if (customer != null) {
            if (customer.portalIntegrations?.includes('Fortnox')) {
                getFortnoxStatus()
            }
    
            if (customer?.portalIntegrations?.includes('Planday')) {
                getPlandayStatus()
            }
        }
    }, [customer])

    return {
        fetchData
    }
}

export default useInitialDataFetch
