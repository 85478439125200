import {ReactNode, useEffect} from "react"
import { useTranslation } from "react-i18next"
import { createUseStyles } from 'react-jss'
import Footer from '../../components/Layout/Footer'
import Header from '../../components/Layout/Header'
import Sidebar from "../../components/Layout/Sidebar/Sidebar"
import { Breakpoints } from "../../constants/breakpoints"
import { Colors } from "../../constants/colors"
import { headerHeight, pageContentMaxWidth, sidebarWidth, statusBarHeight } from "../../constants/dimensons"
import { Fonts } from "../../constants/fonts"
import useInitialDataFetch from "../../hooks/useInitialDataFetch"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { initialDataFetchTriggered } from "../../redux/slices/appStateSlice"
import { formatDateTimeString } from "../../util/date"
import InitializeCappyAuthModal from "../../components/Authentication/InitializeCappyAuthModal";

interface Props {
    children: ReactNode
}

const AuthenticatedLayout = ({ children, ...rest } : Props) => {
    const styles = useStyles()
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common');

    const { fetchData } = useInitialDataFetch()
    const sidebarCollapsed = useAppSelector(state => state.appState.sidebarCollapsed)
    const initialDataFetched = useAppSelector(state => state.appState.initialDataFetchTriggered)
    const customer = useAppSelector(state => state.customer.customer)
    const fortnoxIntegrationActivated = useAppSelector(state => state.fortnoxIntegration.activated)
    const plandayIntegrationActivated = useAppSelector(state => state.plandayIntegration.activated)

    useEffect(() => {
        if (!initialDataFetched) {
            fetchData()
            dispatch(initialDataFetchTriggered())
        }
    }, [])

    const statusBarVisible = () => {
        return customer?.state === 'locked' || 
            customer?.employer?.state === 'locked' || 
            (customer?.employer?.launchDate != null && new Date(customer.employer.launchDate).getTime() > new Date().getTime()) ||
            (customer?.portalIntegrations?.includes('Fortnox') && fortnoxIntegrationActivated === false) ||
            (customer?.portalIntegrations?.includes('Planday') && plandayIntegrationActivated === false)
    }

    const renderStatusBar = () => {
        if (customer?.state === 'locked' || customer?.employer?.state === 'locked') {
            return (
                <div className={styles.statusBarContainer} style={{backgroundColor: Colors.yuan_100}}>
                    <span>{t('serviceLockedMessage')}</span>
                </div>
            )
        } else if (customer?.employer?.launchDate != null && new Date(customer?.employer.launchDate).getTime() > new Date().getTime()) {
            return (
                <div className={styles.statusBarContainer} style={{backgroundColor: Colors.yen_600}}>
                    <span>{t('awaitingLaunchMessage', {date: formatDateTimeString(customer?.employer.launchDate)})}</span>
                </div>
            )
        } else if (customer?.portalIntegrations?.includes('Fortnox') && fortnoxIntegrationActivated === false) {
            return (
                <div className={styles.statusBarContainer} style={{backgroundColor: Colors.yen_600}}>
                    <span>{t('integrationNotActivatedMessage', {integration: 'Fortnox'})}</span>
                </div>
            )
        } else if (customer?.portalIntegrations?.includes('Planday') && plandayIntegrationActivated === false) {
            return (
                <div className={styles.statusBarContainer} style={{backgroundColor: Colors.yen_600}}>
                    <span>{t('integrationNotActivatedMessage', {integration: 'Planday'})}</span>
                </div>
            )
        }
    }

    return (
      <>
          {renderStatusBar()}
          <div className={styles.container} style={{paddingTop: statusBarVisible() ? statusBarHeight : 0}}>
              <div className={styles.sidebarContainer} style={{display: sidebarCollapsed ? 'none' : 'block'}}>
                  <Sidebar/>
              </div>
              <div
                className={`${styles.mainContainer} ${sidebarCollapsed ? styles.mainPaddingCollapsed : styles.mainPaddingVisible}`}>
                  <div className={`${styles.headerContainer} ${sidebarCollapsed ? '' : styles.headerPaddingVisible}`}
                       style={{top: statusBarVisible() ? statusBarHeight : 0}}>
                      <Header/>
                  </div>
                  <div className={styles.pageContentContainer} style={{paddingLeft: sidebarCollapsed ? 10 : 30}}>
                      {children}
                  </div>
                  <Footer/>
              </div>
          </div>
          <InitializeCappyAuthModal/>
      </>
    )
}

export default AuthenticatedLayout

const useStyles = createUseStyles({
    statusBarContainer: {
        position: 'fixed',
        top: 0,
        width: '100%',
        height: statusBarHeight,
        zIndex: 2000,
        fontFamily: Fonts.semiBold,
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        minHeight: '100vh',
        maxWidth: 1680,
    },
    sidebarContainer: {
        position: 'absolute',
        minWidth: sidebarWidth,
        height: '100%',
        zIndex: 1000,
        backgroundColor: '#ffffff',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            position: 'fixed'
        }
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vh',
        backgroundColor: Colors.grayBackgroundApp
    },
    headerContainer: {

        width: '100%',
        minHeight: headerHeight,
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },
    pageContentContainer: {
        flex: 1,
        flexGrow: 1,
        height: '100%',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: pageContentMaxWidth,
        width: '100%'
    },
    mainPaddingCollapsed: {
        paddingLeft: 0,
    },
    mainPaddingVisible: {
        paddingLeft: 0,
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            paddingLeft: sidebarWidth
        }
    },
    headerPaddingVisible: {
        paddingLeft: sidebarWidth,
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            paddingLeft: 0,
        }
    }
})
